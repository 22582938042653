import React from "react"
import { Link, graphql } from "gatsby"

import DetailLayout from "../layouts/detail"
import SEO from "../components/seo"
import HeaderSubNavigation from ".././components/headersubnav"

const PostTemplate = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { next, prev } = pageContext
  return (
    <DetailLayout>
      <SEO title={frontmatter.title} />
      <article>
        <div className="divider" />
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div className="divider" />
      </article>
    </DetailLayout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
