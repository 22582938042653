import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from ".././components/header"
import HeaderSubNavigation from ".././components/headersubnav"
import Footer from ".././components/footer"
import "../styles/styles.scss"

const DetailLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query DetailLayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <HeaderSubNavigation />
        <div id="content">{children}</div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

DetailLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DetailLayout
